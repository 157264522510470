export const MONTH_MIN_LENGTH = 0;
export const MONTH_MAX_LENGTH = 24;
export const REBATE_MIN_VALUE = -9999;
export const REBATE_MAX_VALUE = 9999;
export const REBATE_MAX_DATAVOLUME = 999;

export const ENABLE_SKY_GIGA_ADVANTAGE = false;
export const ENABLE_RECOMMENDATION_WITH_NO_INTEREST = true;
export const DISABLE_EXTRA_FOOTER_HEIGHT = true;
export const ENABLE_RETRADE = true;
export const ENABLE_RECOMMENDATION_PASSES = false;
export const ENABLE_REGIO_DISCOUNT = false;

// 1701385200000 is 01.12.2023
export const DISABLE_CYBER_ADVANTAGE = new Date().getTime() > 1701385200000;
